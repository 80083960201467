import React, { Component } from "react";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";
import { createRoot } from "react-dom/client";
import "./index.css";
import "bootstrap/dist/css/bootstrap.css";
const sentryDns = process.env.REACT_APP_SENTRY_DNS;

if (process.env.NODE_ENV === "production" && sentryDns) {
  console.log(" **** SENTRY INIT **** ", process.env.NODE_ENV);
  Sentry.init({
    dsn: sentryDns,
  });
}

class AppBootstrap extends Component {
  render() {
    return <App />;
  }
}

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<AppBootstrap />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
